<div class="invite-container">
  <mat-card appearance="outlined" class="invite-card">
    <img class="logo" [src]="'./assets/images/agorify-logo.png'" />

    <!-- STATE: loading -->
    <div *ngIf="state.type === 'loading'">
      <mat-spinner class="spinner" [diameter]="30"></mat-spinner>
    </div>

    <!-- STATE: error -->
    <div *ngIf="state.type === 'error'">
      <p class="error-info">{{ state.message }}</p>
    </div>

    <!-- STATE: email-verification-needed -->
    <div *ngIf="state.type === 'email-verification-needed'">
      <h3>Verify your email to continue</h3>
      <p class="verify-info">
        Check your inbox in order to verify your account, or if you haven't
        received verification email click the button below.
      </p>
      <button
        (click)="sendEmailVerification(state)"
        class="register-button"
        mat-raised-button
        color="primary"
        [disabled]="sendingEmail"
      >
        Send another verification email
      </button>
      <div class="register-wrapper">
        <p (click)="logout()" class="logout">Logout</p>
      </div>
    </div>

    <!-- STATE: continue -->
    <div *ngIf="state.type === 'continue'">
      <h3>Invitation</h3>
      <p>
        Hello <span class="invite-details">{{ state.invite.email }}</span>
      </p>
      <p>
        You have been invited by
        <span class="invite-details">{{ state.invite.sentBy }}</span> to join
        <strong class="invite-details">{{ state.invite.orgName }}</strong
        >.
      </p>
      <button (click)="continue(state)" mat-raised-button color="primary">
        Continue
      </button>
    </div>

    <!-- STATE: invite -->
    <div *ngIf="state.type === 'invite'">
      <h3>Invitation</h3>
      <p>
        You have been invited by
        <span class="invite-details">{{ state.invite.sentBy }}</span> to join
        <strong class="invite-details">{{ state.invite.orgName }}</strong
        >.
      </p>
      <p *ngIf="state.user && state.user.email !== state.invite.email">
        Note that this invitation was sent to
        <span class="invite-details">{{ state.invite.email }}</span
        >, but you are signed in as
        <span class="invite-details">{{ state.user.email }}</span
        >.
      </p>
      <button
        (click)="acceptInvite(state)"
        [disabled]="accepting"
        mat-raised-button
        color="primary"
      >
        Accept Invitation
      </button>
      <mat-progress-bar
        *ngIf="accepting"
        color="primary"
        mode="indeterminate"
      ></mat-progress-bar>
      <p (click)="logout()" class="logout">Logout</p>
    </div>
  </mat-card>
</div>
