import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Auth, user } from '@angular/fire/auth';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Observable, from, of, throwError } from 'rxjs';
import { catchError, switchMap, take } from 'rxjs/operators';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  private readonly auth = inject(Auth);
  private readonly router = inject(Router);
  private readonly dialog = inject(MatDialog);

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return user(this.auth)
      .pipe(
        switchMap(user => user ? from(user.getIdToken()) : of(null)),
        take(1),
        switchMap((token) => {
          if (token && request && request.url && request.url.startsWith('/api')) {
            request = request.clone({ setHeaders: { Authorization: `Bearer ${token}` } });
          }
          return next.handle(request);
        }),
        catchError((err) => {
          if (err.status === 401) {
            this.auth
              .signOut()
              .then(() => {
                if (this.dialog) {
                  this.dialog.closeAll();
                }
                this.router.navigateByUrl('/login');
              });
          }
          return throwError(() => err);
        })
      );
  }
}
