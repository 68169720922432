import { Location, NgIf } from '@angular/common';
import { Component, OnInit, inject } from '@angular/core';
import { Auth, User, sendEmailVerification, signOut } from '@angular/fire/auth';
import { FormsModule, ReactiveFormsModule, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { first, switchMap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AuthService } from './auth.service';

declare global {
  interface Window { dataLayer: any; }
}

@Component({
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css'],
  standalone: true,
  imports: [MatCardModule, NgIf, MatProgressSpinnerModule, FormsModule, ReactiveFormsModule, MatFormFieldModule, MatInputModule, MatCheckboxModule, MatButtonModule, MatProgressBarModule, RouterLink]
})
export class RegisterComponent implements OnInit {
  user: User | null = null;
  loading = true;
  sendingEmail = false;
  submitting = false;

  registerForm = new UntypedFormGroup({
    name: new UntypedFormControl('', [Validators.required]),
    email: new UntypedFormControl('', [
      Validators.required,
      Validators.email,
    ]),
    password: new UntypedFormControl('', [Validators.required]),
    termsAndConditions: new UntypedFormControl(false, [Validators.required, Validators.pattern('true')])
  });

  private afAuth: Auth = inject(Auth);

  constructor(
    private snackBar: MatSnackBar,
    private router: Router,
    private authService: AuthService,
    private readonly route: ActivatedRoute,
    private readonly location: Location
  ) {}

  ngOnInit() {
    console.log('RegisterComponent#ngOnInit');

    // If navigation state (not part of url, clears on page reload) includes
    // email, patch the form with it.
    const currentState = this.location.getState() as { email?: string };
    if (currentState && currentState.email) {
      this.registerForm.patchValue({ email: decodeURIComponent(currentState.email) })
    }

    this.authService.currentUser
      .subscribe((currentUser) => {
        if (currentUser) {
          this.user = currentUser;
        }
        this.loading = false;
      });
  }

  async onRegister() {
    const name = this.registerForm.get('name').value;
    const email = this.registerForm.get('email').value;
    const password = this.registerForm.get('password').value;

    this.submitting = true;
    this.route.queryParamMap
      .pipe(
        switchMap((params) =>
          this.authService
            .register(
              name,
              email,
              password,
              params.get('redirect')
                // TODO: use window.location.origin?
                ? `${environment.adminBaseUrl}${this.location.prepareExternalUrl(decodeURIComponent(params.get('redirect')))}`
                : null
            )
        ),
        first()
      )
      .subscribe({
        next: () => {
          const dataLayer = window.dataLayer || [];
          dataLayer.push({
            event: 'signup_email_verification_sent',
          });
          this.submitting = false;
        },
        error: (err) => {
          console.error(`Error happened during register: ${err}`);
          this.snackBar.open('Error happened during register.', null, { duration: 5000 });
          this.submitting = false;
        }
      });
  }

  sendEmailVerification() {
    this.sendingEmail = true;
    sendEmailVerification(this.user, { url: `${window.location.origin}/?welcome=1` })
      .then(() => this.snackBar.open('Email verification sent!', null, { duration: 5000 }))
      .catch(() => this.snackBar.open('Error happened during sending email verification.', null, { duration: 5000 }));

    setTimeout(() => this.sendingEmail = false, 30000);
  }

  logout() {
    signOut(this.afAuth);
    this.router.navigate(['/login']);
  }

}
