<ng-template #loadingOrError>
  <div *ngIf="data$.errorLoading$ | async as error; else loading">
    Something went wrong.
  </div>
  <ng-template #loading>
    <div style="
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 300px;
      ">
      <mat-spinner [diameter]="20"></mat-spinner>
    </div>
  </ng-template>
</ng-template>

<ng-container *ngIf="data$.data$ | async as data; else loadingOrError">
  <div style="display: flex; align-items: center; justify-content: center;  width: 100%; text-align: center;">
    <div *ngIf="data.type === 'success'">
      <h1>Payment Complete</h1>
      <p>Thank you for your purchase.</p>
      <button [routerLink]="data.url" mat-flat-button color="primary">Return back</button>
    </div>
    <div *ngIf="data.type === 'error'">
      <h1>Payment Cancelled</h1>
      <button [routerLink]="data.url" mat-flat-button color="primary">Return back</button>
    </div>
  </div>
</ng-container>