import { Location, NgIf } from '@angular/common';
import { Component, inject } from '@angular/core';
import { Auth, sendPasswordResetEmail } from '@angular/fire/auth';
import { UntypedFormControl, UntypedFormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { from } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCardModule } from '@angular/material/card';

@Component({
    templateUrl: './reset-password.component.html',
    styleUrls: ['./reset-password.component.css'],
    standalone: true,
    imports: [MatCardModule, FormsModule, ReactiveFormsModule, MatFormFieldModule, MatInputModule, NgIf, MatButtonModule, MatProgressBarModule, RouterLink]
})
export class ResetPasswordComponent {
  public loading = false;

  resetPasswordForm = new UntypedFormGroup({
    email: new UntypedFormControl('', [
      Validators.required,
      Validators.email,
    ]),
  });

  private auth: Auth = inject(Auth);

  constructor(
    private snackBar: MatSnackBar,
    private router: Router,
    private readonly route: ActivatedRoute,
    private readonly location: Location
  ) {}

  onSubmit() {
    this.location.prepareExternalUrl
    console.log('ResetPasswordComponent#onSubmit');
    this.loading = true;
    const emailAddress = this.resetPasswordForm.get('email').value;
    this.route.queryParamMap
      .pipe(
        switchMap((params) =>
          from(
            sendPasswordResetEmail(
              this.auth,
              emailAddress,
              params.get('redirect')
                ? {
                  url: `${environment.adminBaseUrl}${this.location.prepareExternalUrl(decodeURIComponent(params.get('redirect')))}`
                }
                : null
            )
          )
        ),
        switchMap(() => this.router.navigate(['/login']))
      )
      .subscribe({
        next: () => {
          this.loading = false;
          this.snackBar.open(`Reset password link is sent to your email address: ${emailAddress}.`, '', { duration: 5000 });
        },
        error: (error) => {
          console.log('error', error);
          this.loading = false;
          if (error.code === 'auth/invalid-email') {
            this.snackBar.open(error.message, '', { duration: 5000 });
          } else if (error.code === 'auth/user-disabled') {
            this.snackBar.open('Your user has been disabled', '', { duration: 5000 });
          } else if (error.code === 'auth/user-not-found') {
            this.snackBar.open('Email is invalid', '', { duration: 5000 });
          } else {
            this.snackBar.open('Reset password link is not sent!', '', { duration: 5000 });
          }
        }
      });
  }
}
