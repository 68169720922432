import { MediaMatcher } from '@angular/cdk/layout';
import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatSidenav } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { RouterLink } from '@angular/router';
import { SharedHeaderComponent } from './shared/shared-header.component';

@Component({
  selector: 'app-root-component',
  templateUrl: './root.component.html',
  styleUrls: ['./root.component.css'],
  standalone: true,
  imports: [
    MatToolbarModule,
    MatCardModule,
    MatButtonModule,
    RouterLink,
    SharedHeaderComponent,
  ],
})
export class RootComponent implements OnInit, OnDestroy {
  @ViewChild('snav', { static: true }) sidenav: MatSidenav;

  public mobileQuery: MediaQueryList | null = null;
  private mobileQueryListener: (evt: MediaQueryListEvent) => void;

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private media: MediaMatcher,
  ) {
    this.mobileQueryListener = (evt: MediaQueryListEvent) => {
      this.changeDetectorRef.detectChanges();
      if (evt.matches) {
        this.sidenav.close();
      }
    };
  }

  ngOnInit(): void {
    this.mobileQuery = this.media.matchMedia('(max-width: 600px)');
    if (this.mobileQuery.addEventListener) {
      this.mobileQuery.addEventListener('change', this.mobileQueryListener);
    } else {
      this.mobileQuery.addListener(this.mobileQueryListener);
    }
  }

  ngOnDestroy(): void {
    if (this.mobileQuery && this.mobileQuery.removeEventListener) {
      this.mobileQuery.removeEventListener('change', this.mobileQueryListener);
    } else if (this.mobileQuery) {
      this.mobileQuery.removeListener(this.mobileQueryListener);
    }
  }

}
