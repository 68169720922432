<div class="register-container">
  <mat-card appearance="outlined" class="register-card">
    <img class="logo" [src]="'./assets/images/agorify-logo.png'" />
    <div *ngIf="!user && !loading">
      <h2 class="center">Get started absolutely free</h2>
      <p class="center grey info-message">
        Free for events up to 200 attendees. No credit card needed.
      </p>
    </div>
    <mat-spinner class="spinner" *ngIf="loading" [diameter]="30"></mat-spinner>
    <form
      *ngIf="!user && !loading"
      [formGroup]="registerForm"
      (ngSubmit)="onRegister()"
      class="register-wrapper"
      autocomplete="off"
    >
      <mat-form-field>
        <input
          id="name-input"
          type="text"
          matInput
          placeholder="Full name"
          formControlName="name"
          autocomplete="off"
          required
        />
        <mat-error *ngIf="registerForm.controls['name'].hasError('required')">
          Name is <strong>required</strong>
        </mat-error>
      </mat-form-field>
      <mat-form-field>
        <input
          id="email-input"
          matInput
          placeholder="Email"
          formControlName="email"
          autocomplete="off"
          required
        />
        <mat-error
          *ngIf="
            registerForm.controls['email'].hasError('email') &&
            !registerForm.controls['email'].hasError('required')
          "
        >
          Please enter a valid email address
        </mat-error>
        <mat-error *ngIf="registerForm.controls['email'].hasError('required')">
          Email is <strong>required</strong>
        </mat-error>
      </mat-form-field>
      <mat-form-field>
        <input
          id="password-input"
          type="password"
          matInput
          placeholder="Password"
          formControlName="password"
          autocomplete="off"
          required
        />
        <mat-error
          *ngIf="registerForm.controls['password'].hasError('required')"
        >
          Password is <strong>required</strong>
        </mat-error>
      </mat-form-field>
      <div class="checkbox-wrapper">
        <mat-checkbox
          color="primary"
          formControlName="termsAndConditions"
        ></mat-checkbox>
        <p class="left grey terms">
          Creating an account means that you agree with our
          <a
            class="blue not-underlined"
            href="https://agorify.com/terms-of-use"
            target="_blank"
            >Terms of Use</a
          >
          and
          <a
            class="blue not-underlined"
            href="https://agorify.com/privacy-notice"
            target="_blank"
            >Privacy Notice</a
          >
        </p>
      </div>
      <button
        id="register-btn"
        class="register-button"
        type="submit"
        mat-raised-button
        color="primary"
        [disabled]="submitting || !registerForm.valid"
      >
        Create account
      </button>
      <mat-progress-bar
        *ngIf="submitting"
        color="primary"
        mode="indeterminate"
      ></mat-progress-bar>
      <a
        [routerLink]="['/login']"
        queryParamsHandling="preserve"
        class="link center top-gap"
      >
        <strong>Already a member? </strong>
        <span class="blue" class="blue">Login.</span>
      </a>
    </form>
    <div
      *ngIf="user && !user.emailVerified && !loading"
      class="register-wrapper"
    >
      <h2 class="center">Verify your email to continue</h2>
      <p *ngIf="user && !loading" class="user-info">
        Registration email: <br />
        <strong>{{ user.email }}</strong>
      </p>
      <p class="verify-info">
        Check your inbox in order to verify your account, or if you haven't
        received verification email click the button below.
      </p>
      <button
        id="send-verify-btn"
        (click)="sendEmailVerification()"
        class="register-button"
        mat-raised-button
        color="primary"
        [disabled]="sendingEmail"
      >
        Send another verification email
      </button>
    </div>
    <div class="register-wrapper" *ngIf="user && !loading">
      <p (click)="logout()" class="logout">Logout</p>
    </div>
  </mat-card>
</div>
