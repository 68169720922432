import { inject, Injectable } from '@angular/core';
import { Auth, user } from '@angular/fire/auth';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { map } from 'rxjs/operators';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard {
  private auth: Auth = inject(Auth);

  constructor(
    private router: Router,
    private dialog: MatDialog,
    // Initialized here
    private readonly authService: AuthService,
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    console.log('AuthGuard#canActivate called');
    return user(this.auth)
      .pipe(
        map(user => {
          console.log('AuthGuard#canActivate user observable', user);
          if (user) {
            if (!user.emailVerified) {
              this.router.navigateByUrl('/register');
              return false;
            }
            return true;
          } else {
            this.dialog.closeAll();
            return this.router.createUrlTree(['/login'], { queryParams: { redirect: state.url ?? '/' } })
          }
        })
      );
  }
}
