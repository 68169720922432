<div class="reset-password-container">
  <mat-card appearance="outlined" class="reset-password-card">
    <img class="logo" [src]="'./assets/images/agorify-logo.png'" />
    <form
      [formGroup]="resetPasswordForm"
      (ngSubmit)="onSubmit()"
      class="reset-password-form"
      autocomplete="off"
    >
      <mat-form-field>
        <input
          matInput
          placeholder="Email"
          formControlName="email"
          autocomplete="off"
        />
        <mat-error
          *ngIf="
            resetPasswordForm.controls['email'].hasError('email') &&
            !resetPasswordForm.controls['email'].hasError('required')
          "
        >
          Please enter a valid email address
        </mat-error>
        <mat-error
          *ngIf="resetPasswordForm.controls['email'].hasError('required')"
        >
          Email is <strong>required</strong>
        </mat-error>
      </mat-form-field>
      <button
        class="reset-password-button"
        type="submit"
        mat-raised-button
        color="primary"
        [disabled]="!resetPasswordForm.valid || loading"
      >
        Reset password
      </button>
      <mat-progress-bar
        *ngIf="loading"
        color="primary"
        mode="indeterminate"
      ></mat-progress-bar>
      <a
        [routerLink]="['/login']"
        queryParamsHandling="preserve"
        class="link center top-gap"
      >
        <strong>Go back to login</strong>
      </a>
    </form>
  </mat-card>
</div>
