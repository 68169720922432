import { Location, NgIf } from '@angular/common';
import { Component, OnInit, inject } from '@angular/core';
import { Auth, signInWithEmailAndPassword } from '@angular/fire/auth';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { combineLatest, from } from 'rxjs';
import { switchMap } from 'rxjs/operators';

@Component({
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
  standalone: true,
  imports: [MatCardModule, FormsModule, ReactiveFormsModule, MatFormFieldModule, MatInputModule, NgIf, RouterLink, MatButtonModule, MatProgressBarModule]
})
export class LoginComponent implements OnInit {
  public loading = false;
  public loginForm = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email]),
    password: new FormControl('', [Validators.required])
  });

  private afAuth: Auth = inject(Auth);

  constructor(
    private readonly snackBar: MatSnackBar,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly location: Location
  ) {}

  ngOnInit(): void {
    // If navigation state (not part of url, clears on page reload) includes
    // email, patch the form with it.
    const currentState = this.location.getState() as { email?: string };
    if (currentState && currentState.email) {
      this.loginForm.patchValue({ email: decodeURIComponent(currentState.email) })
    }
  }

  onSubmit() {
    this.loading = true;
    const email = this.loginForm.value.email;
    const password = this.loginForm.value.password;

    combineLatest([
      this.route.queryParamMap,
      from(signInWithEmailAndPassword(this.afAuth, email, password)),
    ])
      .pipe(
        switchMap(([params]) =>
          from(
            this.router.navigateByUrl(decodeURIComponent(params.get('redirect') ?? '%2F'))
          )
        )
      )
      .subscribe({
        error: (error) => {
          this.loading = false;
          if (error.code === 'auth/invalid-email') {
            this.snackBar.open(error.message, '', { duration: 5000 });
          } else if (error.code === 'auth/user-disabled') {
            this.snackBar.open('Your user has been disabled', '', { duration: 5000 });
          } else if (error.code === 'auth/user-not-found') {
            this.snackBar.open('Email/password combination is invalid', '', { duration: 5000 });
          } else if (error.code === 'auth/wrong-password') {
            this.snackBar.open('Email/password combination is invalid', '', { duration: 5000 });
          }
        }
      });
  }
}
