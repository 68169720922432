import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot } from '@angular/router';
import { map } from 'rxjs';
import { AuthService } from './../auth.service';

export const noActiveSubscriptionGuard: CanActivateFn =
  (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
    const router = inject(Router);
    const authService = inject(AuthService);
    return authService.currentOrg
      .pipe(
        map((org) =>
          !org.contract || org.contract.type === 'free'
            ? true
            : router.createUrlTree(['event', 'organisation'])
        )
      );
  };