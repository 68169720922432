import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { Auth, fetchSignInMethodsForEmail, sendEmailVerification, signOut, User } from '@angular/fire/auth';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { combineLatest, from, of, ReplaySubject } from 'rxjs';
import { first, switchMap, takeUntil } from 'rxjs/operators';
import { AuthService } from './auth.service';
import { OrganisationService, ValidateInviteResponse } from './organisation.service';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { NgIf } from '@angular/common';
import { MatCardModule } from '@angular/material/card';

type LoadingState = {
  type: 'loading';
};

type ErrorState = {
  type: 'error';
  message: string;
};

type ContinueState = {
  type: 'continue';
  invite: ValidateInviteResponse;
  code: string;
};

type EmailVerificationNeededState = {
  type: 'email-verification-needed';
  invite: ValidateInviteResponse;
  user: User;
  code: string;
};

type InviteState = {
  type: 'invite';
  invite: ValidateInviteResponse;
  user: User;
  code: string;
};

type State = LoadingState | ErrorState | ContinueState | EmailVerificationNeededState | InviteState;

@Component({
  templateUrl: './invite.component.html',
  styleUrls: ['./invite.component.css'],
  standalone: true,
  imports: [MatCardModule, NgIf, MatProgressSpinnerModule, MatButtonModule, MatProgressBarModule]
})
export class InviteComponent implements OnInit, OnDestroy {

  public accepting = false;
  public sendingEmail = false;

  state: State = { type: 'loading' };

  private destroyed$ = new ReplaySubject<boolean>(1);
  private auth: Auth = inject(Auth);

  constructor(
    private snackBar: MatSnackBar,
    private router: Router,
    private route: ActivatedRoute,
    private orgService: OrganisationService,
    private authService: AuthService
  ) {}

  ngOnInit() {
    const getCodeAndValidate = this.route.paramMap
      .pipe(
        switchMap((params) => {
          const code = params.get('code');
          return combineLatest([
            of(code),
            this.orgService.validateUserInviteCode(code).pipe(first())
          ]);
        })
      );

    combineLatest([
      this.authService.currentUser,
      getCodeAndValidate,
    ])
      .pipe(
        takeUntil(this.destroyed$)
      )
      .subscribe({
        next: ([user, [code, invite]]) => {
          if (user && user.emailVerified) {
            this.state = { type: 'invite', invite, user, code };
          } else if (user) {
            this.state = { type: 'email-verification-needed', invite, user, code };
          } else {
            this.state = { type: 'continue', invite, code };
          }
        },
        error: (err) => {
          if (err && err.error && err.error.errorCode === 1060) {
            this.state = { type: 'error', message: 'Wrong invitation code' };
          } else if (err && err.error && err.error.errorCode === 1070) {
            this.state = { type: 'error', message: 'Invitation code already used or expired' };
          } else {
            this.state = { type: 'error', message: 'Error happened while validating invitation code' };
          }
        }
      });
  }

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  acceptInvite(currentState: InviteState) {
    this.accepting = true;
    return this.orgService
      .acceptUserInviteByCode(currentState.code)
      .pipe(
        first(),
        switchMap((res) => this.authService.setCurrentOrganisation(res.orgId)),
        switchMap(() => this.router.navigate(["/event"]))
      )
      .subscribe({
        error: (err) => {
          console.error(`Accept invite action failed (userId=${currentState.user.uid}, code=${currentState.code})`, err);
          this.state = { type: 'error', message: 'Failed to accept invite, please try again' };
        }
      });
  }

  continue(currentState: ContinueState) {
    fetchSignInMethodsForEmail(this.auth, currentState.invite.email)
      .then(
        res => {
          this.router.navigate(
            res.length > 0 ? ['/login'] : ['/register'],
            {
              queryParams: {
                redirect: encodeURIComponent(`/invite/${currentState.code}`),
              },
              state: {
                email: encodeURIComponent(currentState.invite.email)
              }
            }
          );
        }
      )
      .catch((err) => {
        console.error(`Continue action failed: ${err}`, err);
        this.state = { type: 'error', message: 'Something went wrong, please try again.' };
      });
  }

  sendEmailVerification(currentState: EmailVerificationNeededState) {
    this.sendingEmail = true;
    sendEmailVerification(currentState.user, { url: `${window.location.origin}/invite/${currentState.code}` })
      .then(() => this.snackBar.open("Email verification sent!", null, { duration: 5000 }))
      .catch(() => this.snackBar.open("Error happened while sending email verification.", null, { duration: 5000 }));
    setTimeout(() => this.sendingEmail = false, 30000);
  }

  logout() {
    from(signOut(this.auth))
      .pipe(first())
      .subscribe();
  }
}
