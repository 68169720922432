import { inject, Injectable } from '@angular/core';
import { Auth, user } from '@angular/fire/auth';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class LoggedInGuard {
  private auth: Auth = inject(Auth);
  private router: Router = inject(Router);

  canActivate() {
    console.log('LoggedInGuard#canActivate called');
    return user(this.auth)
      .pipe(
        map(user => {
          console.log('LoggedInGuard#canActivate user observable', user);
          if (user && user.emailVerified) {
            this.router.navigate(['/']);
            return false;
          }
          return true;
        })
      );
  }
}
