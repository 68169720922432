import { Component, inject } from '@angular/core';
import { Auth, signOut } from '@angular/fire/auth';
import { Router } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';

@Component({
    template: `
    <mat-card appearance="outlined">
      <mat-card-content>
        <h1>Account does not belong to any organisation</h1>
        <p>Either contact your organisation to be invited or contact our support to setup your account.</p>
        <button mat-flat-button color="primary" (click)="logout()">Logout</button>
      </mat-card-content>
    </mat-card>
  `,
    standalone: true,
    imports: [MatCardModule, MatButtonModule]
})
export class NoOrganisationComponent {
  private auth: Auth = inject(Auth);

  constructor(
    private router: Router,
  ) {}

  logout() {
    signOut(this.auth);
    this.router.navigate(['/login']);
  }

}
