import { AsyncPipe, NgIf } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, inject, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ActivatedRoute, Params, RouterLink } from '@angular/router';
import { combineLatest, first, map, Observable, of, switchMap, tap } from 'rxjs';
import { AuthService } from './auth.service';
import { LoadingWrapperWithError } from './shared/loading-wrapper';

declare global {
  interface Window { dataLayer: any; }
}

type Response =
  { type: 'success'; currency: string; amount: number; } |
  { type: 'error'; };

@Component({
  templateUrl: './payment-return.component.html',
  standalone: true,
  imports: [
    MatButtonModule,
    MatProgressSpinnerModule,
    RouterLink,
    NgIf,
    AsyncPipe,
  ]
})
export class PaymentReturnComponent implements OnInit {

  data$: LoadingWrapperWithError<
    { type: 'success'; url: string } |
    { type: 'error'; url: string }
  >;

  private readonly authService = inject(AuthService);
  private readonly http = inject(HttpClient);
  private readonly route = inject(ActivatedRoute);

  ngOnInit(): void {
    this.data$ = new LoadingWrapperWithError(
      combineLatest([
        this.route.queryParams,
        this.authService.currentOrg.pipe(first(), map((org) => org.id)),
      ])
        .pipe(
          switchMap(([queryParams, orgId]) =>
            queryParams.debug
              ? this.handleDebugSession()
              : this.handleTransactionSession(queryParams, orgId)
          )
        )
    );
  }

  private handleTransactionSession(
    queryParams: Params,
    orgId: string,
  ): Observable<{ type: 'success'; url: string } | { type: 'error'; url: string }> {
    if (!queryParams.session_id) {
      return of({ type: 'error', url: queryParams.url ?? '/event/organisation' });
    }

    return this.http
      .post<Response>(`/api/orgs/${orgId}/checkout-session`, { sessionId: queryParams.session_id })
      .pipe(
        switchMap((r) =>
          // TODO: better way to refresh?
          this.authService
            .setCurrentOrganisation(orgId)
            .pipe(
              map(() => r)
            )
        ),
        tap((r) => {
          if (r.type === 'success') {
            // Trigger GTM purchase event on success
            window.dataLayer.push({
              event: 'purchase',
              amount: r.amount,
              currency: r.currency.toLocaleUpperCase(),
              transactionSessionId: queryParams.session_id,
            });
          }
        }),
        map((r) => ({ ...r, url: queryParams.url ?? '/event/organisation' })),
      );
  }

  private handleDebugSession(): Observable<{ type: 'success'; url: string }> {
    console.log('DEBUG: Triggering GTM purchase event');

    window.dataLayer.push({
      event: 'purchase',
      amount: 100,
      currency: 'SEK',
      transactionSessionId: 'DEBUG',
    });

    return of({ type: 'success', url: '/event/organisation' });
  }
}
